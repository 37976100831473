import drawer from './modules/Drawer.js';
import isinview from './modules/IsInView.js';
// import smoothscroll from './modules/SmoothScroll.js'; // use scroll-behavior: smooth;
// import backtopagetop from './modules/BackToPageTop.js';
// import fixednavi from './modules/FixedNavi.js';
import pagescroll from './modules/PageScroll.js';
// import matchheight from './modules/MatchHeight.js';
// import editor from './modules/Editor.js';
// import tab from './modules/Tab.js';
// import toggle from './modules/Toggle.js';

// Draower
drawer();

// IsInView(header)
// isinview(
//     '[data-inview-header]',
//     true,
//     null,
//     '1% 0px -101% 0px',
//     [0]
// );

// IsInView(common)
pagescroll();
isinview('[data-inview]');

// backtopagetop();
// smoothscroll();//use scroll-behavior: smooth;
// fixednavi();
// matchheight('id', '576-');
// matchheight('id', '576-768');
// editor();
// tab();
// toggle();

(function() {
    try {
        document.addEventListener('DOMContentLoaded', () => {
            const items = document.querySelectorAll('[data-dropdown="container"]');
            items.forEach((item) => {
                const trigger = item.querySelector('[data-dropdown="trigger"]');
                const content = item.querySelector('[data-dropdown="content"]');

                trigger.addEventListener('click', (e) => {
                    e.preventDefault();
                    if(content.style.display === 'block') {
                        content.style.display = 'none';
                    } else {
                        content.style.display = 'block';
                    }

                    return false;
                })

                /*
                item.addEventListener('mouseover', () => {
                    content.style.display = 'block';
                })

                item.addEventListener('mouseout', () => {
                    content.style.display = 'none';
                })
                */
            })
        }, {passive: true});
    } catch(e) {
        console.error(e);
    }
})();

(function() {
    document.addEventListener('DOMContentLoaded', () => {
        const body = document.getElementsByTagName('body');
        // body[0].classList.add('is-loader-end');
        body[0].setAttribute('data-status', 'loaded');
    });
})();
