const PageScroll = () => {
    try {
        document.addEventListener('DOMContentLoaded', () => {
            // initialize
            document.body.setAttribute('data-pagescroll-status', 'inactive');

            // scroll event
            window.addEventListener('scroll', ()=> {
                if(window.pageYOffset > 0) {
                    document.body.setAttribute('data-pagescroll-status', 'active');
                } else {
                    document.body.setAttribute('data-pagescroll-status', 'inactive');
                }
            });
        });
    } catch(e) {
        console.error(e);
    }
}

export default PageScroll;
