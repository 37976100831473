// Sample
// isinview('[data-inview]');
// isinview('[data-custom-inview]', {
//     root: null,
//     rootMargin: '-100px 0px 0px 0px',
//     threshold: [1],
//     loop: true,
//     responsive: [{
//         breakpoint: 768,
//         root: null,
//         rootMargin: '-90px 0px 0px 0px',
//         threshold: [0],
//         loop: false,
//     }, {
//         breakpoint: 1024,
//         root: null,
//         rootMargin: '-100px 0px 0px 0px',
//         threshold: [0],
//         loop: false,
//     }, {
//         breakpoint: 1200,
//         root: null,
//         rootMargin: '-110px 0px 0px 0px',
//         threshold: [0],
//         loop: false,
//     }]
// });
// ref: https://coding-memo.work/css_js/924/

const IsInView = (selector, settings) => {
    const statusDataAtt = 'data-inview-status';
    const statusDataActiveFlag = 'active';
    const statusDataInactiveFlag = 'inactive';

    function getBpOptions(options) {
        // responsive is array of settings
        const {responsive} = options;

        if(! responsive) {
            return options;
        }

        const currentWidth = window.innerWidth;

        let bpOptions = options;
        for(let i = 0; i < responsive.length; i += 1) {
            if(currentWidth < responsive[i].breakpoint) {
                bpOptions = responsive[i];
                break;
            }
        }

        return bpOptions;
    }

    function intersectingCallBack(entry) {
        // entry.style.height = `auto`;
    }

    function notIntersectingCallBack(entry) {
        // const rect = entry.getBoundingClientRect();
        // entry.style.height = `${Math.round(rect.height)}px`;
    }

    function getObserver(options) {
        // const observer = new IntersectionObserver(callback, options);
        // callbackを無名関数として定義している
        // console.log(options);

        const observer = new IntersectionObserver((entries, object) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if(options.intersectingCallBack) {
                        intersectingCallBack(entry.target);
                    }

                    entry.target.setAttribute(statusDataAtt, statusDataActiveFlag);

                    if(!options.loop) {
                        object.unobserve(entry.target);
                    }
                } else if(options.loop) {
                    if(options.notIntersectingCallBack) {
                        notIntersectingCallBack(entry.target);
                    }

                    entry.target.setAttribute(statusDataAtt, statusDataInactiveFlag);
                }
            });
        }, options);

        return observer;
    }

    try {
        document.addEventListener('DOMContentLoaded', () => {
            if ('IntersectionObserver' in window) {
                // default settings
                const baseSettings = {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    // rootMargin: '-50% 0px -50% 0px',
                    // rootMargin: '0px 0px -100% 0px',
                    // rootMargin: '10px 0px -101% 0px',
                    threshold: [0],
                    loop: false,
                    intersectingCallBack: false,
                    notIntersectingCallBack: false,
                    responsive: null,
                }

                // settings
                settings = Object.assign(baseSettings, settings);

                // selector
                if(selector  === undefined){
                    throw new Error('Selector is mandantory in IsInView.');
                }
 
                // target
                const items = [].slice.call(document.querySelectorAll(selector));

                // options 
                let options = getBpOptions(settings);

                // observer
                let observer = getObserver(options);

                items.forEach((item) => {
                    observer.observe(item);
                });

                // for responsive
                let currentOptions = options;

                window.addEventListener('resize', () => {
                    options = getBpOptions(settings);

                    if(currentOptions !== options) {
                        currentOptions = options;

                        // reset
                        items.forEach((item) => {
                            observer.unobserve(item);
                            // console.log('unobserve');
                        });

                        // observer
                        observer = getObserver(options);

                        items.forEach((item) => {
                            observer.observe(item);
                            // console.log('observe');
                        });
                    };
                });
            }
        }, {passive: true});
    } catch(e) {
        console.error(e);
    }
}

export default IsInView;

// Sample
// isinview(
//     '[data-inview-header]',
//     true,
//     null,
//     '1% 0px -101% 0px',
//     [0]//0:after interaction 1: before interaction
// );
// const IsInView = (selector, isToggle, ...[root, rootMargin, threshold]) => {
//     try {
//         // selector
//         if(selector  === undefined){
//             throw new Error('Selector is mandantory in IsInView.');
//         }
// 
//         // isToggle
//         isToggle = isToggle||false;
// 
//         // options
//         const options = {
//             root: root||null,
//             rootMargin: rootMargin||'0px 0px 0px 0px',
//             // rootMargin: '-50% 0px -50% 0px',
//             // rootMargin: '0px 0px -100% 0px',
//             // rootMargin: '10px 0px -101% 0px',
//             threshold: threshold||[0]
//         }
// 
//         document.addEventListener('DOMContentLoaded', () => {
//             const items = [].slice.call(document.querySelectorAll(selector));
// 
//             if ('IntersectionObserver' in window) {
//                 const observer = new IntersectionObserver((entries, object) => {
//                     entries.forEach((entry) => {
//                         if (entry.isIntersecting) {
//                             entry.target.setAttribute('data-inview-status', 'active');
//                             if(!isToggle) {
//                                 object.unobserve(entry.target);
//                             }
//                         } else if(isToggle) {
//                             entry.target.setAttribute('data-inview-status', 'inactive');
//                         }
//                     });
//                 }, options);
//  
//                 items.forEach((item) => {
//                     observer.observe(item);
//                 });
//             }
//         }, {passive: true});
//     } catch(e) {
//         console.error(e);
//     }
// }
// 
// export default IsInView;
