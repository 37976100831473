const Drawer = () => {
    const triggerId = '[data-drawer="trigger"]';
    const contentId = '[data-drawer="content"]';
    const closingTime = 375;

    const drawerToggle = (e) => {
        if(document.body.dataset.drawer !== 'open') {
            document.body.setAttribute('data-drawer', 'open');
        } else {
            document.body.setAttribute('data-drawer', 'closing');
            let timer;
            const timeout = setTimeout(() => {
                clearInterval(timer);
                clearTimeout(timeout);
                document.body.setAttribute('data-drawer', 'close');
            }, closingTime);
        }

        // check
        // const textNode = document.createTextNode(e.type);
        // console.log(textNode);
    }

    try {
        document.addEventListener('DOMContentLoaded', () => {
            // eventlistener for trigger and overlay
            const entries = [].slice.call(document.querySelectorAll(triggerId));
            document.body.setAttribute('data-drawer', 'close');

            entries.forEach((entry) => {
                entry.addEventListener('click', (e) => {
                    // e.preventDefault();
                    drawerToggle(e);
                }, {passive: false});

                // 20230808 delete
                // entry.addEventListener('touchstart', (e) => {
                //     // e.preventDefault();
                //     drawerToggle(e);
                // }, {passive: false});
            });

            // eventlistener for a tag in content
            if(document.querySelector(contentId)) {
                const anchors = [].slice.call(document.querySelector(contentId).getElementsByTagName('a'));
                anchors.forEach((anchor) => {
                    anchor.addEventListener('click', (e) => {
                        // e.preventDefault();
                        drawerToggle(e);
                    }, {passive: false});

                    // 20230808 delete
                    // anchor.addEventListener('touchstart', (e) => {
                    //     // e.preventDefault();
                    //     drawerToggle(e);
                    // }, {passive: false});
                });
            }
        }, {passive: false});
    } catch(e) {
        console.error(e);
    }
}

export default Drawer;
